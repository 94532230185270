import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {Input as ReactInput } from "reactstrap";

// Storybook
import { Button, Typography, Spinner, Modal} from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio,DatePicker, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// App
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"

import OptionRightInput from "components/Forms/OptionRightInput/OptionRightInput"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import CommissionSelect from "components/Forms/CommissionSelect/CommissionSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"



import { httpGetStockData } from "services/stocks"
import { httpGetPrivateStockDatas } from "services/private_stocks"
import { 
  httpGetTransaction, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
  httpGetBatchesGtZero

} from "services/transactions"

import ExchangeTransactionsCreate from 'views/app/ExchangeTransactionsCreate/ExchangeTransactionsCreate'
import ExchangeTo from 'views/app/ExchangeTo/ExchangeTo'


import { 
  httpGetPrivateInvestimentFundDatas
} from "services/private_investment_funds";

import { httpGetCurrencies } from "services/currencies"
import { set, startOfDay } from "date-fns";

// registerLocale('fi', fi)


const defaultValues = {
  id: null,
  // commission_total: "0",
  currency: "EUR",
  exchange_type: 1,
  sell_out_batches: true,
  date_transaction: new Date(),
  last_batch_date_transaction: new Date(),
  date_settlement: new Date(),
  // date_settlement: moment().format("YYYY-MM-DD"),
}

const PurchaseEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     setFieldError,
     setFieldTouched,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     objectId,
     setValues,
     setErrors,
      setTouched,
   } = props;
   
   const {
      t,
      history,
      currencyDate,
      setCurrencyDate,
      stocks,
      stockMarket,
      setStockMarket,
      setInvestmentType,
      investmentType,
      fundType,
      setFundType,
      totalValue,
      setTotalValue,
      totalValueEur,
      setTotalValueEur,
      stocksLoading,
      setCurrentStock,
      setFilters,
      portfolio,
      setExchangeValues,
      exchangeValues,
      forms,
      setForms

   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const [stockDatePrice, setStockDatePrice] = useState();
  const [stockDatePriceLoading, setStockDatePriceLoading] = useState(false);
  
  const [stockTickerSymbol, setStockTickerSymbol] = useState(null);
  const [customErrors, setCustomErrors] = useState({});

  const [calculatePriceClicked, setCalculatePriceClicked] = useState(false);
  const [calculatePriceTotalClicked, setCalculatePriceTotalClicked] = useState(false);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const [show_hided_basicdata, setShowHidedBasicData] = useState(false);

  
  const onSubmit = (e) => {
    e.preventDefault();

    let err = null
    forms.forEach((form) => {
      let security = null
      let purchase_costs = null
      if(form?.security == "" || form?.security == null){

        security = t("Kenttä 'Arvopaperi' on pakollinen")
        err = true
      }

      if(form?.percentage == null || form?.percentage == ""){
        purchase_costs = t("Kenttä 'Menojen osuus %' on pakollinen")
        err = true
      }


      setErrors({
        ...errors,
        security: security,
        purchase_costs: purchase_costs
      })


    })

   if(err){
      console.log("errors.purchase_costs", errors.purchase_costs)
   } 
   else{
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {

        handleSubmit()
      }
      else {
        // console.log(res)
        setTouched(res);
        // console.log(res)
        if (stockMarket === "PRIVATE") {

          setErrors({
            ...res,
            stock_private_obj: res?.stock_private_obj?.id ? res?.stock_private_obj?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
        else {
          setErrors({
            ...res,
            stock: res?.stock?.id ? res?.stock?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
      }
    })
   } 

  }

  // console.log(setFieldError)

  const findStockObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }

    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])





  //----------------------------UPDATE BASICDATA when stock market change----------------------------------------------------
  useEffect(() => {
    if (investmentType) {
      // console.log("PRIVATE basic_data")
      // getPrivateBasicDataObjects()
      let param ={
        stock_market:stockMarket,
        related_model_type: investmentType,
        show_hidden:show_hided_basicdata

      }

      if (investmentType == "30" ){
        param ={...param, type: fundType}
      }
      getBasicDataObjects(param)
    }
    else {
    getBasicDataObjects({stock_market:stockMarket,  show_hidden:show_hided_basicdata})
    }
    // if (values)
    // setFieldValue('stock', null)
  }, [stockMarket, investmentType, show_hided_basicdata])
  


  //----------------------------UPDATE totalValue STATE----------------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission_total = parseFloat(values?.commission_total ? values?.commission_total : 0)



    setTotalValue(round(price_total+commission_total, 2))
    
  }, [values?.price_total, values?.commission_total])
  
  /* Optional: if you want to calc price reversed
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const quantity = parseInt(values?.quantity)
    let calculation = 0
    if (price_total && price_total > 0 && quantity && quantity > 0) {
      calculation = price_total / quantity
    }
    if (calculation && calculation != parseFloat(values?.price)) {
      setFieldValue("price", calculation)
    }
  }, [values?.price_total, values?.quantity])
  */
  

  //----------------------------UPDATE TOTAL PRICE Automatically ----------------------------------------------------


  const handleCalculatePriceTotal = () => {
    setCalculatePriceClicked(false)
    setCalculatePriceTotalClicked(true)
    const quantity = parseFloat(values?.quantity)
    const price = parseFloat(values?.price)
    let calculation = 0
    if (price && quantity && price > 0 && quantity > 0) {
      calculation = price*quantity;
    }
    if (calculation && calculation !== parseFloat(values?.price_total)) {
      setFieldValue("price_total", parseFloat(roundScaleDecimals(calculation, 6)))
    }
    setTimeout(() => {
      setCalculatePriceTotalClicked(false);
    }, 1000);
  }

  useEffect(() => {
    //handleCalculatePriceTotal()
  }, [values?.price, values?.quantity])


  useEffect(() => {
    let currency = portfolio?.currency_label


    if (stockMarket == "XHEL"){
      currency = "EUR"
    }
    else if (stockMarket == "XSTO"){
      currency = "SEK"
    }
    else if (stockMarket == "XICE"){
      currency = "ISK"
    }
    else if (stockMarket == "XCSE"){
      currency = "DKK"
    }

    const stock = findStockObject(values?.stock?.id)
    if (stock?.currency_label){
      currency = stock?.currency_label
    }
    else if (stock?.currency){
      currency = stock?.currency
    }

    setFieldValue("currency", currency )
  }, [stockMarket, values?.stock])



  //----------------------------Calculate PRICE based on value_total and quantity ----------------------------------------------------


  const handleCalculatePrice = () => {
    setCalculatePriceClicked(true)
    setCalculatePriceTotalClicked(false)
    const quantity = parseFloat(values?.quantity)
    const price_total = parseFloat(values?.price_total)
    let calculation = 0
    setTimeout(() => {
      setCalculatePriceClicked(false);
    }, 1000);
    if (price_total && quantity ) {
      calculation = price_total /quantity;
    }
    else {return}
    setFieldValue("price", parseFloat(roundScaleDecimals(calculation, 6)))
  }




  //----------------------------UPDATE STOCK PRICE  {values.stock.id}----------------------------------------------------

  const findObject = (id) => {
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  useEffect(() => {
    if ((values?.stock && values?.date_transaction)|| (values?.stock_private_obj && values?.date_transaction)) {
      let stockObject = findObject( stockMarket === "PRIVATE"? values?.stock_private_obj :values?.stock?.id)


      let ticker_symbol =values?.ticker_symbol; // "ACG1V";
      if (stockObject) {
        ticker_symbol = stockObject?.ticker_symbol
        setStockTickerSymbol(stockObject?.ticker_symbol)
        // setFieldValue("stock", stockObject)
      }
      else {
        setStockTickerSymbol(null)
      }
      const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
      let params = {
        offset:0, 
        limit:1, 
        // stock_date: "2021-12-26", // this exact filter not working
        ticker_symbol: ticker_symbol,
      }
      
      // Stock date

      if (stockMarket === "PRIVATE") {
        params["stock_date__gte"] = moment.utc(values?.date_transaction).startOf('day').toDate()
        params["stock_date__lte"] = moment(values?.date_transaction).add("days",1).toISOString().split('T')[0] +" 23:59"; 
        
        params["private_stock"] = values?.stock_private_obj?.id
        httpGetPrivateStockDatas(params).then(res => {
          if (res?.data?.count > 0) {
            setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price, 2).toFixed(2)))
          }
          else {
            setStockDatePrice(null)
          }
        })
      }
      else {
        params["stock_date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
        params["stock_date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 

        if (investmentType == "10" && ticker_symbol){
          params["ticker_symbol"] = ticker_symbol;
          setStockDatePriceLoading(true)
          httpGetStockData(params).then(res => {
            if (res?.data?.count > 0) {
              
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.ending_price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "20"){
          params["private_stock"] = values?.stock?.id;

          setStockDatePriceLoading(true)
          httpGetPrivateStockDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "30"){
          params["private_investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetPrivateInvestimentFundDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
      }
    }
  }, [values?.stock, values?.stock_private_obj, values?.date_transaction])


  useEffect(() => {
    if (values?.stock || values.stock_private_obj) {
      let stockObject = findObject(values?.stock?.id)
      if (stockObject) {
        if (stockMarket === "PRIVATE") {
          setFieldValue("stock_private_obj", {...values.stock_private_obj, label: stockObject?.name})
        }
        else {
        setFieldValue("stock", {...values.stock, label: stockObject?.name})
        
        }
      }
    }
  }, [stocks])
  

  //------------------------UPDATE COMISSION TOTAL-----------------------------------------------

  


  // ------------------UPDATE CURRENCY DATE-----------------------------------

  


  // ------------------UPDATE TOTAL VALUE EUR-----------------------------------
  useEffect(() => {
    if (currencyDate && totalValue ) {
      setTotalValueEur(round(totalValue/currencyDate, 2))
    }
    else {
      setTotalValueEur(totalValue)
    }
      
      
  }, [currencyDate, totalValue])
  

  // -------------------------SET PRICE automatically if in field have no value already--------------------------------------------
  useEffect(() => {
    // if (!values.price) {
    //   handleUseStockDatePrice()
    // }
    // handleUseStockDatePrice()
  }, [stockDatePrice])
            



  // ------------------fUCTIONS TO USE-----------------------------------

  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    /*
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    })
    */
  }

  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
    setFieldValue("stock", null)
    setFieldValue("stock_private_obj", null)
    setStockTickerSymbol(null)
    setStockDatePrice(null)
    // if (investmentType === "10") {
    //   //filters are from transactionslist, so when this modal are closed, transactionslist view stock_market is same as there.
    //   setFilters(s => ({
    //     ...s,
    //     stock_market: target.value,
    //   }))
    // }
    // else {
    //   setFilters(s => ({
    //     ...s,
    //     stock_market: null,
    //   }))
    // }


    if (target.value === "PRIVATE") {
      setErrors({
        ...errors,
        stock_private_obj: t("Kenttä on pakollinen"),
      })
    }
    else {
      console.log(errors)
      setErrors({
        ...errors,
        stock: t("Kenttä on pakollinen"),
      })
    }

  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value === "10") {
      setStockMarket("XHEL")
      // setFilters(s => ({
      //   ...s,
      //   related_model_type: "10",
      // }))
      setInvestmentType(target.value)   
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
    }
    else {
      setStockMarket(null)
      setInvestmentType(target.value)
      // setFilters(s => ({
      //   ...s,
      //   // related_model_type: target.value,
      //   // stock_market: null,
      // }))
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
    }
  } 

  const handleFundChange = ({target}) => {
    setFundType(target.value)  
    setFieldValue("stock", null) 
} 

  const getExchangeTypeText = useCallback(() => {
    return values?.exchange_type == 1? t(''):
           values?.exchange_type == 2? t("exchange_type_2_info_text", 'Rahastoannilla tarkoitetaan maksutonta osakeantia.'):
           values?.exchange_type == 3? t("exchange_type_3_info_text", 'Merkintäoikeusannissa merkitään kenttään "Merkintähinta yht." lunastettujen osakkeiden kpl määrä kerrottuna merkintähinnalla. '):
           values?.exchange_type == 4? t("exchange_type_4_info_text", 'Yhdistelmäannissa saa x määrän uutta osaketta ilmaiseksi sekä voi lunastaa x määrän merkintähintaan. Toiminta sama kuin merkintäoikeusannissa, mutta uusi määrää kenttään tulee ilmaiset + lunastetut kpl'): null
  }, [values?.exchange_type])

  const getDefaultValues = useCallback(() => {
    // if (location?.state?.stockId && !values?.private_stock && !values?.stock==="stock_market_changed") {
    //   return {value: location.state?.stockId, label: location.state?.related_model_type}
    // }
    if (values?.stock_market === "PRIVATE") {
    return {value: values?.private_stock, label: values?.private_stock_name }
    }
    return {value: values?.stock, label: values?.stock_name }
  }, [values])

  
  const handleUseStockDatePrice = () => {
    if (stockDatePrice && !objectId) {
      setFieldValue("price", Number(stockDatePrice.toFixed(2)))
    }
  }


  
  const handleDelete = () => {

    if (stockMarket === "PRIVATE") {
      httpDeletePrivateStockTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})

        props.handleResolve()
      }, error => {
        
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})
      })
    }
    else {
      httpDeleteTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})
        if (investmentType == "10"){
          setFilters(s => ({
              ...s,
              // stock_market: stockMarket,
              // related_model_type: investmentType,
              stock_market: null,
              related_model_type: null,
            }))
      }
      else {
          setFilters(s => ({

              ...s,
              // stock_market: null,
              // related_model_type: investmentType,
              stock_market: null,
              related_model_type: null,
            }))
      }
        props.handleResolve()
      }, error => {
        
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})
      })
    }
  }
  


  const getStockSelect = useCallback(() => {
    if (stockMarket === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("purchase_order_edit_asset_stock", "Osake (yksityinen)")}
          setFieldValue={setFieldValue}
          setValueToSelect={true}
          ratesView={false}
          setFieldValueReturnId={false}
        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label=" "
          setFieldValue={setFieldValue}
          setValues={setValues}
          setFieldValueReturnId={false}
          ratesView={false}
          setValueToSelect={true} 
        />
      )
    }
  }, [investmentType, stockMarket, values?.stock, values.stock_private_obj, errors?.stock, errors?.stock_private_obj, errors])

  const [batches, setBatches] = useState([])

  useEffect(() => {

    if (values?.stock){

    let batchsParams = {
          
      stock_market: investmentType != 10? null: stockMarket,
      related_model_id: values?.stock?.id,
      related_model_type: investmentType,
      portfolio: portfolio?.id,  
      ordering: "date_transaction",
      type:2, 
      sdate_transaction__date__lte: values?.last_batch_date_transaction ? moment(values?.last_batch_date_transaction).format("YYYY-MM-DD") : undefined,
    }
    batchsParams["batch_select"] = values?.batch_select?.id
    httpGetBatchesGtZero(batchsParams).then(res => {

      setBatches(res?.data)
    }, error => {
        setBatches([])
        if (error?.status === 400) {
          setErrors({
            general: error?.data?.stock
          })
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    }
  }, [values?.stock, values?.stock_private_obj, values?.last_batch_date_transaction])



  useEffect(() => {
    setExchangeValues({  forms: forms,batches: batches, investmentType: investmentType, stock: values?.stock?.value, portfolio: portfolio?.id, date_transaction: values?.date_transaction,sell_out_batches: values?.sell_out_batches })
  }, [forms, investmentType, values?.stock, portfolio, batches, values?.sell_out_batches])



  const handleAddForm = () => {
    setForms([...forms, { related_model_type: '10',  stock_market: 'XHEL', security:'', ratio: '', capital_return:'', increase_value:'', notes:'',currency:'', sum_new_quantity:'', percentage:"",increase_costs:"", showExtraField: false}]);
  };

  const exchangeSetFieldValue = (index, name, value) => {
    const newForms = [...forms];
    
    newForms[index][name] = value;
    setForms(newForms);

  }

  const handleFormChange = (index, event) => {
    const newForms = [...forms];
    console.log(event.target)
    
    //If checkbox then use checked value and if extra field are closed then set them value to empty string.    
    if (event.target?.id?.includes("showExtraField")){
      newForms[index][event.target.name] = event.target.checked;
      if (event.target.checked == false){
        newForms[index]['increase_value'] = ""
        newForms[index]['increase_costs'] = ""
      }
    }
    else{
      newForms[index][event.target.name] = event.target.value;
    }
    if (newForms[index]['related_model_type'] != 10){
      newForms[index]['stock_market'] = null
    }
    else{
      if (newForms[index]['stock_market'] == null)  {
        newForms[index]['stock_market'] = "XHEL"
      }
    }
    setForms(newForms);
  };

  const handleFormRemove = (index) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  // const getSecurities = ()


  const getForm = () => {

    return (
      <div className="mb-0">
        {forms.map((form, index) => (
          <div key={index}>
              <ExchangeTo
                index={index}
                form={form}
                handleFormRemove={handleFormRemove}
                handleFormChange={handleFormChange}
                exchangeSetFieldValue={exchangeSetFieldValue}
                setValues={setValues}
                setExchangeValues={setExchangeValues}
                showExtraField= {values?.exchange_type == 3? true:false}
              />
          </div>
        ))}
        <Button  variant="secondary"  size="sm" onClick={handleAddForm}>{t("Lisää uusi arvopaperi")}</Button>
      </div>
    );
  }


  
  return (
    <>
    <div className="px-3 pt-2">

        {

          // {JSON.stringify(values)}
          // {"---------------------"}
          // {JSON.stringify(investmentType)}
          // {JSON.stringify(stockMarket)}
          // {JSON.stringify({skk:"df"})}
          // {JSON.stringify(batches)}
          // {JSON.stringify(errors)}
          // {JSON.stringify(forms)}
        }
        

      <Row className="mt-2 mb-2 px-3 border-bottom">
        <Col xs={12} md={6} className="ml-auto pb-3 pl-3 d-flex align-items-center">

          {objectId ? (
            <Typography variant="h2"  >{t("exchange_order_edit_title", "Muokkaa ostotapahtumaa")}</Typography>
          ) : (
            <Typography variant="h2">{t("exchange_order_add_title", "Valitse vaihto")}</Typography>
          )}
        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/>
        </Col>
      </Row>
      <Row  className="py-3 px-3 ">
        <Col xs={12} md={6} className="border-right px-4 pt-0">
          <Row>
              <Col className="mb-0">
              <label className="mb-1 pr-1 header-bold">{t("exchange_from_title", "Mistä vaihdetaan")}</label>
              </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("exchange_edit_type", "Vaihdon tyyppi")}</label>
            </Col>
            <Col className="mb-1">
                <Input {...getFormikInputProps("exchange_type")} type="select">
                  <option value="1">{t(`exchange_type_1`, "Vaihto")}</option>
                  <option value="2">{t(`exchange_type_2`, "Rahastoanti")}</option>
                  <option value="3">{t(`exchange_type_3`, "Merkintäoikeusanti")}</option>
                  <option value="4">{t(`exchange_type_4`, "Yhdistelmäanti")}</option>
                </Input>
            </Col>
          </Row>


          <Row>
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("investment_type_select_label", "Rahoitusinstrumentti")}</label>
            </Col>
            <Col className="mb-1">
            <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange} value={investmentType} showAll={false} label=" "/>
            </Col>
          </Row>
         {
            investmentType === 30 || investmentType === "30" ? (
            <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("exchange_edit_type", "tyyppi")}</label>
              </Col>
              <Col>
                <InvestmentTypeSelectSub name="fund_type" label=" " onChange={handleFundChange} value={fundType} showPrivate={false} showAll={false} icon=""  disabled={objectId? true: false}/>
              </Col>
            </Row>
            ) : null
          }
          {
            investmentType === 10 || investmentType === "10" ? (
            <Row className="mt-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("exchange_edit_stock_market", "Pörssi")}</label>
              </Col>
              <Col className="mb-1">
                <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} showPrivate={false} showAll={false} icon="" label=" "/>
              </Col>
            </Row>
            ) : null
          }
          <Row className="mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("security", "Arvopaperi")}</label>
            </Col>
            <Col className="mb-1">
            { getStockSelect() }



            </Col>
          </Row>
          <Row className="row justify-content-end ">
            <Col lg={8} md={12} className="mb-3">
              { stockTickerSymbol ? (
                <Typography variant="small">{t("Kaupankäyntitunnus")}: {stockTickerSymbol}</Typography>
                
              ) : null }
            </Col>
          </Row>
            {(investmentType === 10 || investmentType === "10" ) && 

              <Row>
            <Col sm={12} lg={4} className="d-flex align-items-center">
              
              </Col>
              <Col className="my-1">
              <div className="ml-0 mb-3">
                <Checkbox 
                  id={234234234}
                  name="show_hided_basicdata"
                  checked={show_hided_basicdata}
                  onChange={(event) =>  setShowHidedBasicData(!show_hided_basicdata)}
                  value="show_hided_basicdata"
                  className="zIndexZero"
                  required
                  label={<>{t("show_hided_basicdata", 'Näytä poistuneet')}</>}
                  />
              </div>
              </Col>
            </Row>
            }
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("exchange_date_transaction", "Vaihtopäivä")}</label>
            </Col>
            <Col className="mb-1">
              {/* <DatePicker
                className="form-control"
                selected={values?.date_transaction}
                name="date_transaction"
                onChange={(date) => setFieldValue("date_transaction", date)}
                showYearDropdown
                // dateFormat="dd.M.yyyy HH:mm"
                dateFormat="Pp"
                placeholderText="15.1.2000 12:00"
                value={values?.date_transaction}
                disabled={objectId? true: false}
                showTimeSelect
                timeIntervals={1}
                // timeCaption="Time"
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_transaction).format("dddd"))}/>}

              /> */}


              <DatePicker
                    className="form-control"
                    selected={values?.date_transaction}
                    name="date_transaction"
                    onChange={(date) => {
                      if (date && !date.getHours() && !date.getMinutes()) {
                        // Set the default time to 12:00 if no time is given
                        date.setHours(12);
                        date.setMinutes(0);
                        date.setSeconds(0);
                      }
                      setFieldValue("date_transaction", date);
                    }}
                    showTimeSelect
                    dateFormat="dd.M.yyyy HH:mm"
                    isClearable={false}
                    placeholderText={t("Kaikki")}
                // customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_transaction).format("dddd"))}/>}
                />

              {/*
              <Input
                {...getFormikInputProps("date_transaction")}
                label={t("sale_edit_date_transaction", "Myyntipäivä")}
                type="date"
              />
              */}
            
            </Col>
          </Row>
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3 d-flex">
            <Typography variant="small">{moment(values?.date_transaction).format("dddd")}</Typography>
            <Typography variant="small" className="ml-auto text-warning" >{t("syötä myös kellon aika")}</Typography>

            </Col>
          </Row> */}
          {portfolio?.sell_profit_calculation_method =="FIFO"  && 
          <>
                    <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("exchange_included_batches_date", "Huomioitavat erät ennen pvm ")}</label>
            </Col>
            <Col className="mb-3">
              <DatePicker
                className="form-control"
                selected={values?.last_batch_date_transaction}
                name="last_batch_date_transaction"
                onChange={(date) => setFieldValue("last_batch_date_transaction", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.date_transaction}
                disabled={objectId? true: false}
                timeIntervals={1}
                timeCaption="Time"
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.last_batch_date_transaction).format("dddd"))}/>}

              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
            <div className="row justify-content-start">
              <div className="ml-3 mb-3" >
                  <Checkbox 
                    id="sell_out_batches"
                    name="sell_out_batches"
                    checked={values?.sell_out_batches}
                    onChange={(e) => {setFieldValue("sell_out_batches",e.target.checked)}}
                    value="sell_out_batches"
                    label={<>{t('Kaikki erät myydään / vaihdetaan')}</>}
                    className="zIndexZero"
                  />
                </div>
            </div>
            </Col>
          </Row>
          </>
          }

     

          {/* 
          <Row className="row justify-content-start mt-5">
            <Col lg={12} md={12} className="mb-1 text-left">
                <Typography className="text-red" variant="medium">
                   {t("HUOM! Muista tehdä salkun sisällä samaa osaketta koskevat kirjaukset aina kronologisessa järjestyksessä.")}
                </Typography>

              </Col>
          </Row> */}
          { (investmentType == 20 || investmentType == 40 ) &&
            <Row className="row justify-content-start mt-3">
              <Col lg={12} md={12} className="mb-1 text-left">
                  <Typography className="text-warning" variant="medium">
                    {t("exchange_split_first_info_text", "Annissa osakkeen määrä muuttuu, joten käy lisäämässä kurssikirjausten split")}
                  </Typography>

                </Col>
            </Row>
          }


          <Row className="row justify-content-start mt-3">
          <Col lg={12} md={12} className="mb-1 text-left">
              <Typography className="text-warning" variant="medium">
                 {getExchangeTypeText()}
              </Typography>

            </Col>
        </Row>

        </Col>
        <Col className="px-4 pt-0">
          <Row>
            <Col className="mb-0">
               <label className="mb-1 pr-1 header-bold">{t("exchange_to_information", "Mihin vaihdetaan")}</label>
            </Col>
          </Row>
 
          {getForm()}

        </Col>

      </Row>
    
      
      <Row className="pt-3 px-3 bg-lighter rounded-bottom">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
        </Col>
        { values?.id > 0 ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        ) : null}
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              { t("Jatka") }
            </Button>
          )}
          { Boolean(errors["general"]) && <Typography className="text-danger">{t(errors["general"])}</Typography> }
          { Boolean(errors["purchase_costs"]) && <Typography className="text-danger">{t(errors["purchase_costs"])}</Typography> }
          { Boolean(errors["security"]) && <Typography className="text-danger">{t(errors["security"])}</Typography> }
        </Col>

      </Row>
        </div>
    </>
  );
}

const PurchaseEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {  
      const {t, stockMarket} = props;

      const required = t("Kenttä on pakollinen");

        return Yup.object().shape({
          stock: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
          // quantity: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          // price: Yup.string().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          // price: Yup.string().test(
          //   "not-0",
          //   t("Value cannot be '0'"),
          //   (value) => value !== "0"
          // )
          
          // value_total: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable()
        });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, currencyDate, portfolio, stockMarket, investmentType, totalValue, setFilters,} = props;

      let data = {}

      data = {
        ...values,
        commission_total: -values?.commission_total || 0,
        currency_exchange_rate: currencyDate,
        currency_label: values?.currency,
        portfolio: portfolio?.id,
        price: -values?.price,
        price_total: -values?.price_total,
        // stock: values?.stock_private_obj?.id,
        stock: values?.stock?.id,
        type: 2, // Buy
        date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
        stock_market: stockMarket,
        related_model_id: values?.stock?.id,
        related_model_type: investmentType,
        // state: values?.state?.key,
        value_total: -totalValue,
        batch_balance: values?.quantity,
        original_batch_balance: values?.quantity,
        reference_code: values?.reference_code,
      };
      
      if (values?.id) {
          setSubmitting(true)

          //only backend can update batch_balance
          delete data.batch_balance
          delete data.date_transaction
          
          if (investmentType != "10"){
            data.ticker_symbol = null
            data.stock_market = null
          }

          httpUpdateTransaction(values?.id,data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            props.handleResolve()

          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
      }
      else {
          if (investmentType != "10"){
            data.ticker_symbol = null
            data.stock_market = null
          }

          // const oldDate = new Date(data.date_transaction);
          // const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
          // const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
          // data.date_transaction = formattedDate;
          setSubmitting(true)
          props.openCreate()
          props.handleResolve()


 

      }

    },
    displayName: "BasicForm"
  
})(PurchaseEdit)
    
    
const PurchaseEditView = ({history, objectId,defaultStockMarket, defaultInvestmentType="10", ticker_symbol, setFilters, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(defaultValues);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [totalValue, setTotalValue] = useState(0);
  const [totalValueEur, setTotalValueEur] = useState(0);
  const [stockMarket, setStockMarket] = useState(defaultStockMarket? defaultStockMarket : "XHEL");
  const [investmentType, setInvestmentType] = useState(defaultInvestmentType? defaultInvestmentType : "10");
  const [currencyDate, setCurrencyDate] = useState(1.0);
  const [currentStock, setCurrentStock] = useState(null);
  const [fundType, setFundType] = useState("private_stock");

  
  // Store
  const options = useStoreState((state) => state.options?.options);
  const addOption = useStoreActions((actions) => actions.options.addOption);
  const stocks = useStoreState((state) => state.stocks?.objects);
  const stocksLoading = useStoreState((state) => state.stocks?.loading);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);

  const [forms, setForms] = useState([{ related_model_type: '10', stock_market: 'XHEL', security:'', ratio: '', capital_return:'', increase_value:'', notes:'',currency:'', sum_new_quantity:'', percentage:"",increase_costs:"", showExtraField: false }]);

  
  useEffect(() => {
    if (portfolio) {
      setPreSetValues(s => ({
        ...s,
        // currency: portfolio?.currency_label
        currency: "EUR"
      }))
    }
  }, [portfolio])

  const findObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }

    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])

   // Modal: New/Edit Sale
  
   const [modalEditExchangeOpen, setModalEditExchangeOpen] = useState(false)
   const [modalEditExchangeId, setModalEditExchangeId] = useState()
   
   const toggleModalEditExchange = () => {
     setModalEditExchangeOpen(s => !s);
   }
   
   const openModalEditExchange = (id, related_model_type, ticker_symbol) => {
      console.log("openModalEdit")
     setModalEditExchangeOpen(true)
     setModalEditExchangeId(id);
   }
   
   const modalEditExchangeResolve = () => {
     setModalEditExchangeOpen(false);
     setModalEditExchangeId(null);
     // getTransactions()
   //  getUnmadeExchangeTransactions()
 
     
   }
   
   const modalEditExchangeReject = () => {
     setModalEditExchangeOpen(false);
     setModalEditExchangeId(null);
   }


  
  const getObject = (id) => {
    setLoading(true)

    if (stockMarket === "PRIVATE") {
      httpGetPrivateStockTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market_label)
  
        getPrivateBasicDataObjects({stock_market:res?.data?.stock_market_label})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock_private_obj:{id: res?.data?.stock, value:res?.data?.stock, label: findObject(res?.data?.stock)?.name},
  
          price: round(res?.data?.price, 2) * -1,
          value_total : round(res?.data?.value_total, 2) ,
          price_total : round(res?.data?.price_total, 2) ,
          commission_total : round(res?.data?.commission_total, 2) ,
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }
    else{
      httpGetTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market)
  
        getBasicDataObjects({stock_market:res?.data?.stock_market, related_model_type: investmentType})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock:{id: res?.data?.related_model_id, value:res?.data?.related_model_id, label: findObject(res?.data?.related_model_id)?.name},
  
          price: roundScaleDecimals(res?.data?.price, 6) * -1,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
          ticker_symbol : ticker_symbol
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }


    
  }
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
    
   
    <PurchaseEditFormik 
      t={t}
      history={history}
      preSetValues={preSetValues}
      notify={notify}
      objectId={objectId}
      addOption={addOption}
      currencyDate={currencyDate}
      setCurrencyDate={setCurrencyDate}
      portfolio={portfolio}
      stocks={stocks}
      privateStocks={privateStocks}
      stockMarket={stockMarket}
      setStockMarket={setStockMarket}
      setInvestmentType={setInvestmentType}
      investmentType={investmentType}
      setFundType={setFundType}
      fundType={fundType}
      setFilters={setFilters}
      totalValue={totalValue}
      setTotalValue={setTotalValue}
      totalValueEur={totalValueEur}
      setTotalValueEur={setTotalValueEur}
      stocksLoading={stocksLoading}
      currentStock={currentStock}
      findeObject={findObject}
      openModal={openModalEditExchange}
      forms={forms}
      setForms={setForms}

      {...rest} 
    />

    </>
    )
  

}
  

export default PurchaseEditView;
